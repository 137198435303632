import { useSuspenseQuery } from "~/queries/useSuspenseQuery";

interface User {
  name: string;
  username: string;
  avatarUrl: string;
  isProfileWeak: boolean;
  favoriteOffersCount: number;
  messagesCount: number;
  notificationsCount: number;
  managedCompanies: Array<{
    name: string;
    slug: string;
    logoUrl: string;
    verified: boolean;
    area: { [locale: string]: string };
  }>;
  agreedToGdpr: boolean;
  currency: "CZK" | "EUR" | "USD";
}

interface VerifiedSession {
  twoFactorVerified: true;
  user: User;
}

interface UnverifiedSession {
  twoFactorVerified: false;
  user: null;
}

export type Session = VerifiedSession | UnverifiedSession;

export const useUserQuery = () => {
  const queryKey = ["session", "user"];

  return useSuspenseQuery<User | null>({
    queryKey,
    queryFn: async () => {
      const session = await $apiFetch<Session | null>(`/api/front/user`);

      if (session?.twoFactorVerified) {
        return session.user;
      }

      return null;
    },
  });
};
